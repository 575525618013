import { EnvironmentStoreDetails } from "../types";

const eu = {
	integration: {
		hash: "cuq27ftwb9",
		channel: 1612109,
		tokens: {
			rest: "7lx9mgic1acddr24dzyyk3hp6opjdv0",
			graphql:
				"eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOlsxNjEyMTA5XSwiY29ycyI6W10sImVhdCI6MTg4NTYzNTE3NiwiaWF0IjoxNzQxMTczMDc3LCJpc3MiOiJCQyIsInNpZCI6MTAwMzEyNDg5NSwic3ViIjoiODZmeGNvbWJidXY2YmgybHZwNTYxZXF1bnBpNmgxZSIsInN1Yl90eXBlIjoyLCJ0b2tlbl90eXBlIjoyfQ.dEZAaB0RACdeSJUqurO2goT9jNxwgC4M_oYms-I9qbvrjkfQFBuMIY3xM92MYonilkKtIupRglQevGTOY6c6IQ",
			B3: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdG9yZV9oYXNoIjoiY3VxMjdmdHdiOSIsImRiIjoiZGVmYXVsdCIsImVtYWlsIjoiYmVuLnBhbG1lckA1ODc0LmNvLnVrIiwibmFtZSI6IkppdHRlcmJpdCBJbnRlZ3JhdGlvbiIsImV2ZW50X2NoYW5uZWwiOiJhcHAiLCJ0b2tlbl92ZXJzaW9uIjoidjMifQ.zpTKKv-ud_SZ9EHdXmS13HvRy7jKHBeqwGv3aUtL7IY",
		},
		clientId: "86fxcombbuv6bh2lvp561equnpi6h1e",
		clientSecret:
			"2c57f07d7eed1e80895a7319481bc696fa966f2cb6c2c05c444d98121360aeb6",
		locale: {
			default: "en",
			supported: ["en", "fr"],
		},
	},
	staging: {
		hash: "9d6i4y56ze",
		channel: 1, // actual channel is 1606754 but graphql fails,
		tokens: {
			rest: "18gkbzrxn1rpwz37fkc0a277alftnf2",
			graphql:
				"eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOjEsImNvcnMiOlsiaHR0cHM6Ly9tb3ZvcmEuY29tIl0sImVhdCI6MTg4NTYzNTE3NiwiaWF0IjoxNzA2NTMzMTU3LCJpc3MiOiJCQyIsInNpZCI6MTAwMjgxMDI3NSwic3ViIjoibmRvOHB0dmFldHVudzRqdDRieDYzZDB1bHVyNW1nIiwic3ViX3R5cGUiOjIsInRva2VuX3R5cGUiOjF9.FxuciRYr7QJ1qAg-C_sClFKH8j58nadcaiG42hPGoKILONgrGIWpXt6-pkbKP8Vy0ZDTcDMN0Hjru_JeeH8VVw",
			B3: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdG9yZV9oYXNoIjoiOWQ2aTR5NTZ6ZSIsImRiIjoiZGVmYXVsdCIsImVtYWlsIjoiam9zaEA1ODc0LmNvLnVrIiwibmFtZSI6IkhlYWRsZXNzIFN0b3JlZnJvbnQiLCJldmVudF9jaGFubmVsIjoiYXBwIiwidG9rZW5fdmVyc2lvbiI6InYzIn0.zDN9guNQqabrkFKHkX7x1MX1fs-ft23UgCPX4YCPCqw",
		},
		clientId: "4uas3c0j0ovquekj6tmhm4smzjhs0p2", //do8ptvaetunw4jt4bx63d0ulur5mg",
		clientSecret:
			"c488bc2698e492e3220ce1e2b79e71958316b2f3087a53c881b18e0fd0084ead",
		//"a5fe340502df86d3d96b0842840d6e60d47bee926e4ca33488f0e6a8973d7a63",
		locale: {
			default: "en",
			supported: ["en", "fr"],
		},
	},
	production: {
		hash: "cld0m4ayim",
		channel: 1671008,
		tokens: {
			rest: "m8qpyd1snihukkdbslli90n5gvw1o8v",
			graphql:
				"eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOlsxNjcxMDA4XSwiY29ycyI6WyJodHRwczovL3Byb2R1Y3Rpb24ubW92b3JhLmNvbSJdLCJlYXQiOjE4ODU2MzUxNzYsImlhdCI6MTczMTMzOTk4MCwiaXNzIjoiQkMiLCJzaWQiOjEwMDI3NTU5NzIsInN1YiI6IjNlOG05OHIxcHNscTduenYwM3Y3dWUydDhoZm4ydXciLCJzdWJfdHlwZSI6MiwidG9rZW5fdHlwZSI6MX0.nsu8iaXPtWAmcAG6FCvGmTEhwFcKXMBArbL7X3-nnRiXM02TTNGqdx7dzqfMmKzA53h5avdcZO9K8oQ-WrXIyw",
			B3: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdG9yZV9oYXNoIjoiY2xkMG00YXlpbSIsImRiIjoiZGVmYXVsdCIsImVtYWlsIjoiam9zaEA1ODc0LmNvLnVrIiwibmFtZSI6IkhlYWRsZXNzIFN0b3JlZnJvbnQiLCJldmVudF9jaGFubmVsIjoiYXBwIiwidG9rZW5fdmVyc2lvbiI6InYzIn0.Qs3Tg8wBvvNFimdPYETSmym9FkzBqfE6znMSMpvpvUI",
		},
		clientId: "3e8m98r1pslq7nzv03v7ue2t8hfn2uw",
		clientSecret:
			"37bfb91d42a09b88b67724c21f8c806c1f2824e76bb3c6d8238a6f29596b4463",
		locale: {
			default: "en",
			supported: ["en"],
		},
	},
} as EnvironmentStoreDetails;

export default eu;
