import { EnvironmentStoreDetails } from "../types";

const ca = {
	integration: {
		hash: "eh5ijzv424",
		channel: 1714625,
		tokens: {
			rest: "nsood4jdivartvy1m1y4srghqghcrkr",
			graphql:
				"eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOlsxNzE0NjI1XSwiY29ycyI6W10sImVhdCI6MTg4NTYzNTE3NiwiaWF0IjoxNzQxMDgwMjQ3LCJpc3MiOiJCQyIsInNpZCI6MTAwMzEyNDg4Niwic3ViIjoiNGl5NDB0bzNvZ3RwZGRoNm15cGpwOXQ3dXFxYWpuZyIsInN1Yl90eXBlIjoyLCJ0b2tlbl90eXBlIjoyfQ.7kKniIfmKTEvR8KOciulrF2zSzWYJSQjpcUdwBk9wifUMw1Hd9BEvlwIfADTZfEVr2N2WCCuBF96D3NBvFyZhw",
			B3: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdG9yZV9oYXNoIjoiZWg1aWp6djQyNCIsImRiIjoiZGVmYXVsdCIsImVtYWlsIjoiYmVuLnBhbG1lckA1ODc0LmNvLnVrIiwibmFtZSI6Ik1vdm9yYSBJbnRlZ3JhdGlvbiIsImV2ZW50X2NoYW5uZWwiOiJhcHAiLCJ0b2tlbl92ZXJzaW9uIjoidjMifQ.ok0Y1Tu1A3KZYD35HDne_XxEB-o7yqrEVe9TTLEDFPM",
		},
		clientId: "4iy40to3ogtpddh6mypjp9t7uqqajng",
		clientSecret:
			"74f3c8590fd415d7cca65fed9f903600c9e6a912335d4c175644bc220fe9d1b5",
		locale: {
			default: "en",
			supported: ["en", "fr"],
		},
		msbc: {
			tenantId: "8ef58b6b-d7e0-48e0-ab8a-03bbdf36e7f5",
			clientId: "a0ecfed6-524c-40fc-9a41-a3fce38c91d5",
			clientSecret: "KZC8Q~lhlMX.RGDm1K~.b4rEeeVZfqRRgAI1FdgK",
		},
	},
	staging: {
		hash: "if4jwxrxdm",
		channel: 1709944,
		tokens: {
			rest: "d4pe3kkew6elclwu49yh16j8417m2ln",
			graphql:
				"eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOlsxNzA5OTQ0XSwiY29ycyI6W10sImVhdCI6MTg4NTYzNTE3NiwiaWF0IjoxNzQwMTM1NzE5LCJpc3MiOiJCQyIsInNpZCI6MTAwMzMzMjAxNiwic3ViIjoiYXFpeTA1b3Bxbjh0dm8xZjdqOTYyOHVnZTdlN25uYSIsInN1Yl90eXBlIjoyLCJ0b2tlbl90eXBlIjoyfQ.GRzM2c_u9pERMPZIUTO5PUVZKjeQ3d7ZoOD7SipxkjMMHr1IMlVuRYY2Krqn6Ld8e7tStPsfKuR0Ipl6x8ZtmQ",
			B3: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdG9yZV9oYXNoIjoiaWY0and4cnhkbSIsImRiIjoiZGVmYXVsdCIsImVtYWlsIjoiam9zaEA1ODc0LmNvLnVrIiwibmFtZSI6IjU4NzQgSkl0dGVyYml0IiwiZXZlbnRfY2hhbm5lbCI6ImFwcCIsInRva2VuX3ZlcnNpb24iOiJ2MyJ9.Sb8fhdYOM7KX403nDyvy2Y8rlc4jqPTAy1JvdAnDUK0",
		},
		clientId: "aqiy05opqn8tvo1f7j9628uge7e7nna",
		clientSecret:
			"49c424a6cc0fda8164c11925674b5ced1d8f699668ed02ba67d8365f4c2c2340",
		locale: {
			default: "en",
			supported: ["en", "fr"],
		},
		msbc: {
			tenantId: "8ef58b6b-d7e0-48e0-ab8a-03bbdf36e7f5",
			clientId: "a0ecfed6-524c-40fc-9a41-a3fce38c91d5",
			clientSecret: "KZC8Q~lhlMX.RGDm1K~.b4rEeeVZfqRRgAI1FdgK",
		},
	},
	production: {
		hash: "zt4n5ulm2t",
		channel: 1709939,
		tokens: {
			rest: "t59xgz9qiuts8ox5vajiy9qxzrrcrep",
			graphql:
				"eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOlsxNzA5OTM5XSwiY29ycyI6W10sImVhdCI6MTg4NTYzNTE3NiwiaWF0IjoxNzQwMTM1MDUxLCJpc3MiOiJCQyIsInNpZCI6MTAwMzMzMDEwMCwic3ViIjoiaGppcmxnenZ3YjM1bW1tbWh2N25lYWFiZ3AzbWtmZyIsInN1Yl90eXBlIjoyLCJ0b2tlbl90eXBlIjoyfQ.7m536lL8Lo9eKdtBLk-9bth7K4pOMEq_8y9g1pAZ7dAGL6ZjcegJw9zMx1LQ3B8gpYLooo6MjybeluTDhvxKPw",
			B3: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdG9yZV9oYXNoIjoienQ0bjV1bG0ydCIsImRiIjoiZGVmYXVsdCIsImVtYWlsIjoiam9zaEA1ODc0LmNvLnVrIiwibmFtZSI6IjU4NzQgSml0dGVyYml0IiwiZXZlbnRfY2hhbm5lbCI6ImFwcCIsInRva2VuX3ZlcnNpb24iOiJ2MyJ9.8YXuaJCF3ruKOsPzn1Er30pDVCu9jWtHdSQNUk_B5F4",
		},
		clientId: "hjirlgzvwb35mmmmhv7neaabgp3mkfg",
		clientSecret:
			"87ddbe8cbdfb7ec11f324e03c358785d1b3a24f0b51d9a894e80f5439dc36d98",
		locale: {
			default: "en",
			supported: ["en"],
		},
		msbc: {
			tenantId: "8ef58b6b-d7e0-48e0-ab8a-03bbdf36e7f5",
			clientId: "a0ecfed6-524c-40fc-9a41-a3fce38c91d5",
			clientSecret: "KZC8Q~lhlMX.RGDm1K~.b4rEeeVZfqRRgAI1FdgK",
		},
	},
} as EnvironmentStoreDetails;

export default ca;
