import { Environment, StoreDetails } from "../types";
import ca from "./ca.store";
import eu from "./eu.store";
import us from "./us.store";

const stores = {
	ca,
	us,
	eu,
};

export const storesWithCertificationChecks = ["ca", "us"];

export const getEnvironment = (): Environment => {
	return (process.env.BIGCOMMERCE_ENVIRONMENT || "staging") as Environment;
};

export const getStores = () => {
	const environment = getEnvironment();

	const configsForEnvironment = Object.entries(stores)
		.map(([region, details]) => {
			const config = details[environment];

			if (!config) {
				return null;
			}

			return [region, details[environment]];
		})
		.filter(Boolean) as [string, StoreDetails][];

	return Object.fromEntries(configsForEnvironment);
};
