import { EnvironmentStoreDetails } from "../types";

const us = {
	integration: {
		hash: "aopoxyecp7",
		channel: 1715117, // actual channel is 1612111 but graphql fails,
		tokens: {
			rest: "m6x2syrieggkdsa3ewalfd4wuyu4s5j",
			graphql:
				"eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOlsxNzE1MTE3XSwiY29ycyI6W10sImVhdCI6MTg4NTYzNTE3NiwiaWF0IjoxNzQxMTczODc1LCJpc3MiOiJCQyIsInNpZCI6MTAwMzEyNDg4MCwic3ViIjoiM3F1NGwzMnJrZmJyM2l6bDJqdW1zNHRzb245ZDdueCIsInN1Yl90eXBlIjoyLCJ0b2tlbl90eXBlIjoyfQ.WdHDD30tDrhh_pl_Y5xXEZcTCKuJyEiVKUODkj2auhavY4wsoAGyuM9OnjlB-X-i3HDbsFrEcGn2r0AOcsctDw",
			B3: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdG9yZV9oYXNoIjoiYW9wb3h5ZWNwNyIsImRiIjoiZGVmYXVsdCIsImVtYWlsIjoiYmVuLnBhbG1lckA1ODc0LmNvLnVrIiwibmFtZSI6Ik1TQkMgSW50ZWdyYXRpb24iLCJldmVudF9jaGFubmVsIjoiYXBwIiwidG9rZW5fdmVyc2lvbiI6InYzIn0.IybSd2EKtBdsak1qurb3ntwyeKVEmMSXfiQbpDaGhM0",
		},
		clientId: "3qu4l32rkfbr3izl2jums4tson9d7nx",
		clientSecret:
			"3d3810d8d17f0369b8a4007bf86e10f7fdddb53e40f8f26d269ec3a44ebe0307",
		locale: {
			default: "en",
			supported: ["en"],
		},
	},
	staging: {
		hash: "m6fm6y0wvl",
		channel: 1709955,
		tokens: {
			rest: "sqb1sby2ejgv9p1enfa8amne3e3sqbi",
			graphql:
				"eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOlsxNzA5OTU1XSwiY29ycyI6W10sImVhdCI6MTg4NTYzNTE3NiwiaWF0IjoxNzQwMTM2NjYwLCJpc3MiOiJCQyIsInNpZCI6MTAwMzMzMjAyMSwic3ViIjoiYjJ2MXpmaXlueHl5azk4NDdrNmh1Z3IydnZ2MmdrNCIsInN1Yl90eXBlIjoyLCJ0b2tlbl90eXBlIjoyfQ.sePimRWRROW3W3f04V2vXjw0Dbu89y8-jLLywuN6G0os8Dj0QP3C5ta7vZegHgSznCv6qoBqq11ze2lShjJbFQ",
			B3: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdG9yZV9oYXNoIjoibTZmbTZ5MHd2bCIsImRiIjoiZGVmYXVsdCIsImVtYWlsIjoiam9zaEA1ODc0LmNvLnVrIiwibmFtZSI6IjU4NzQgSml0dGVyYml0IiwiZXZlbnRfY2hhbm5lbCI6ImFwcCIsInRva2VuX3ZlcnNpb24iOiJ2MyJ9.hbR1NaeqJkWQPdGfGjkrM6YRmQTHrxAMtnn2XGg1uho",
		},
		clientId: "b2v1zfiynxyyk9847k6hugr2vvv2gk4",
		clientSecret:
			"fed2051ad0a288e8a16a3b978e6f715f85e9fa4d3efceffaee97445bb564df2b",
		locale: {
			default: "en",
			supported: ["en"],
		},
	},
	production: {
		hash: "rggjnms3qa",
		channel: 1709949,
		tokens: {
			rest: "30gggpoa6xugng5tcinrll33lrcw3i4",
			graphql:
				"eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOlsxNzA5OTQ5XSwiY29ycyI6W10sImVhdCI6MTg4NTYzNTE3NiwiaWF0IjoxNzQwMTM2MjcxLCJpc3MiOiJCQyIsInNpZCI6MTAwMzMzMDEwMywic3ViIjoiZXpyZzdqamh4djZ2NHoyejNleWJtbzFqcHdmZDhxdCIsInN1Yl90eXBlIjoyLCJ0b2tlbl90eXBlIjoyfQ.dP2DGbFfKVh2F_fbeXfp0UraEicwiaBKW8QzCHhaOOrcbGyntmOqeXloXbFhQwOQU4D4fgFquO0YgECvPuW0Pg",
			B3: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdG9yZV9oYXNoIjoicmdnam5tczNxYSIsImRiIjoiZGVmYXVsdCIsImVtYWlsIjoiam9zaEA1ODc0LmNvLnVrIiwibmFtZSI6IjU4NzQgSml0dGVyYml0IiwiZXZlbnRfY2hhbm5lbCI6ImFwcCIsInRva2VuX3ZlcnNpb24iOiJ2MyJ9.pv0R27jZ-DBjtI4NcYG8aS5VEERWe5O1heakO686kZM",
		},
		clientId: "ezrg7jjhxv6v4z2z3eybmo1jpwfd8qt",
		clientSecret:
			"fd7d3a66760d44bf1aa6cbc89af9cf69d8a7f724dd2d06ec01207fbec05faad4",
		locale: {
			default: "en",
			supported: ["en"],
		},
	},
} as EnvironmentStoreDetails;

export default us;
